<template>
  <Popup ref="popupRef" width="500px" title="驳回原因" confirm-text="确定" @confirm="confirm" @close="resetForm">
    <el-input
      type="textarea"
      :rows="8"
      :maxlength="150"
      show-word-limit
      placeholder="请输入驳回原因，不超过150字"
      v-model="inputValue">
    </el-input>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { auditEnterpriseShortNameApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      inputValue: '',
      mid: ''
    }
  },
  methods: {
    open(option) {
      this.mid = option.mid || ''
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.inputValue = ''
    },
    confirm() {
      if(!this.inputValue.trim()) return this.$tips({message: '请输入驳回原因', type:'warning'})
      const formData = {
        mid: this.mid,
        auditReason: this.inputValue,
        act: 2
      }
      console.log('formdata', formData)
      auditEnterpriseShortNameApi(formData).then(res => {
        console.log('审核驳回', res)
        this.$tips({message: res.msg, type:'success'})
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  div {
    flex: 1;
    width: 137px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
  }
}
</style>